let toastContainer: HTMLElement

if (import.meta.client) {
  toastContainer = document.createElement('ul')
  toastContainer.setAttribute('role', 'dialog')
  toastContainer.id = 'toast-container'
  toastContainer.className = 'v-toast-container'
  document.body.appendChild(toastContainer)
}

const useToast = () => {
  const renderToast = (msg = '', type = 'success') => {
    const toastElement = document.createElement('li')
    toastElement.innerHTML = msg
    toastElement.setAttribute('role', 'alert')
    toastElement.className = `toast toast--${type}`
    document.getElementById('toast-container')?.appendChild(toastElement)

    setTimeout(() => {
      toastElement.remove()
    }, 3000)
  }

  const displaySuccess = (message: string) => {
    renderToast(message)
  }

  const displayError = (message: string) => {
    renderToast(message, 'error')
  }

  return { displaySuccess, displayError }
}

export default useToast
